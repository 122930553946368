import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { User as AuthUser } from 'oidc-client-ts';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { Auth, getAuth, MultiFactorResolver } from 'firebase/auth';
import { FirebaseApp, initializeApp } from 'firebase/app';
@Injectable({
  providedIn: 'root',
})
export class UEAPIService {

  public firebaseConfig = {
    apiKey: 'AIzaSyCXLXyECxMH7vNMnELl4iC_-627ZvSfyPA',
    authDomain: 'enigmaai-prod.firebaseapp.com',
    projectId: 'enigmaai-prod',
    storageBucket: 'enigmaai-prod.appspot.com',
    messagingSenderId: '344674701360',
    appId: '1:344674701360:web:7d5c0d4a7827fc26b3ddf5'
  };
  public auth: Auth;
  public app: FirebaseApp;
  public multiFactorResolver: MultiFactorResolver;

  user: AuthUser | any;
  socket: any;
  private _apiRoot = process.env.API_ROOT;
  private apiURL = process.env.API_URL;
  private csrfToken: any;
  private _headers: HttpHeaders;
  private _requestOptions: any;
  userInfo: Subject<any>;
  nonclassified = false;
  // private selectedCustomerSub = new BehaviorSubject<string>('');
  // selectedCustomer$ = this.selectedCustomerSub.asObservable();

  constructor(
    private _http: HttpClient,
    private _authService: AuthService
  ) {
    this.initializeClient();
    this.initiateAuth();
  }

  public async initiateAuth(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.app = initializeApp(this.firebaseConfig);
        this.auth = getAuth(this.app);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  // setSelectedValue(value: string): void {
  //   this.selectedCustomerSub.next(value);
  // }

  async initializeClient() {
    if (localStorage.getItem('idToken')) {
      this._headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('idToken') as string}`,
      });
      this._requestOptions = { headers: this._headers };
    }
  }

  initCsrfProtection(): void {
    this.getCsrfToken().subscribe({
      next: (res: any) => {
        this.csrfToken = res.csrfToken;
        localStorage.setItem('csrfToken', this.csrfToken);
        this._headers = this._headers.set('X-XSRF-TOKEN', this.csrfToken);
      },
      error: (err: any) => {
        console.error('CSRF Token Fetch Error:', err);
      }
    });
  }

  getCsrfToken(): Observable<any> {
    const idToken = localStorage.getItem('idToken'); 
    const headers = new HttpHeaders({
    Authorization: `Bearer ${idToken}` 
  });
    return this._http.get(`${this.apiURL}/csrf-token`, { headers, withCredentials: true });
  }

  public checkUser() {
    const idToken: string = localStorage.getItem('idToken') || '';
    const headers = new HttpHeaders().set('Authorization', `Bearer ${idToken}`);
    return this._http.get(`${this.apiURL}/checkUser`, { headers });
  }

  public sendVerificationEmail(): Observable<any> {
    return this._http.get(`${this.apiURL}/sendVerificationEmail`);
  }

  public removeUserMfa(email: string): Observable<any> {
    return this._http.post(`${this.apiURL}/removeUserMfa`, { email });
  }

  public login(email: string, password: string, employeeId: string, customerId: string, firstName: string, lastName: string) {
    let req: any = {
      email,
      password,
      employeeId,
      customerId,
      firstName: firstName ? firstName : email.charAt(0),
      lastName: lastName ? lastName : email.charAt(1)
    };
    return this._http.post(`${this.apiURL}/userLogin`, req);
  }

  public firebaseSignIn(email: string, secretKey: string, employeeId: string, customerId: string) {
    let req: any = {
      email,
      secretKey,
      employeeId,
      customerId,
    };
    return this._http.post(`${this.apiURL}/firebaseSignIn`, req);
  }

  public refreshAuthToken(): Observable<string> {
    const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
    
    if (!userDetails.email || !userDetails.secretKey) {
      return throwError(() => new Error('User details not found'));
    }
    
    // Create an observable to emit the result
    return new Observable<string>((observer) => {
      this.firebaseSignIn(userDetails.email, userDetails.secretKey, userDetails.employeeId, userDetails.customerId)
        .subscribe({
          next: (res: any) => {
            if (res?._tokenResponse?.idToken) {
              localStorage.setItem('idToken', res._tokenResponse.idToken);
              observer.next(res._tokenResponse.idToken);
              observer.complete();
            } else {
              observer.error(new Error('Token not found in response: ' + JSON.stringify(res)));
            }
          },
          error: (err) => {
            console.error('Error in refreshAuthToken:', err);
            observer.error(err ?? new Error('Unknown error in refreshAuthToken'));
          }
        });
    });
  }

  public getInsights(request: any) {
    let req: any = {
      email: request,
    };
    return this._http.post(`${this.apiURL}/insightsById`, req);
  }

  public getKpiScoreBoard(request: any) {
    return this._http.post(`${this.apiURL}/kpiScoreBoardById`, request);
  }

  public getLogEvents(request: any) {
    return this._http.post(`${this.apiURL}/logEventsById`, request);
  }

  public getLogSize(request: any) {
    return this._http.post(`${this.apiURL}/logSizeById`, request);
  }

  public getAnomalies(request: any) {
    return this._http.post(`${this.apiURL}/anomaliesById`, request);
  }

  public getNetworkTraffic(request: any) {
    return this._http.post(`${this.apiURL}/networkTrafficById`, request);
  }

  public getReports(request: any) {
    return this._http.post(`${this.apiURL}/downloadReports`, request);
  }

  public getUniqueSystem(request: any) {
    return this._http.post(`${this.apiURL}/uniqueSystemList`, request);
  }

  public getUniqueDestinationSystem(request: any) {
    return this._http.post(`${this.apiURL}/uniqueSystemListDestination`, request);
  }

  public getTableData(data: any): Observable<any> {
    return this._http.post<any>(`${this.apiURL}/classificationsList`, data);
  }

  public getDestinationJobs(data: any): Observable<any> {
    return this._http.post<any>(`${this.apiURL}/uniqueDestinationList`, data);
  }

  public getClassificationList(): Observable<any> {
    return this._http.get<any>(`${this.apiURL}/userClassificationList`);
  }

  public updateClassificationById(data: any): Observable<any> {
    return this._http.put(`${this.apiURL}/updateClassificationById`, data);
  }

  public updateDestinationById(data: any): Observable<any> {
    return this._http.put(`${this.apiURL}/updateDestinationById`, data);
  }

  public getUser(userId: string): Promise<any> {
    return this._http
      .get(`${this._apiRoot}/account/${userId}`, this._requestOptions)
      .toPromise();
  }

  public getUserProfile(): Observable<any> {
    const employeeId: string = localStorage.getItem('userId') as string;
    return this._http.get<any>(`${this.apiURL}/getUserProfile/${employeeId}`);
  }

  public updateUserProfile(data: any[]): Observable<any> {
    return this._http.patch(`${this._apiRoot}/profile`, data);
  }

  public getUserRole(): Observable<any> {
    return this._http.get(`${this._apiRoot}/access/validate`);
  }

  // public getAllOrganizations(): Observable<any> {
  //   return this._http.get(`${this._apiRoot}/access/my/organizations`);
  // }

  public createUser(user: UEUser): Promise<any> {
    return this._http
      .post(`${this._apiRoot}/account`, user, this._requestOptions)
      .toPromise();
  }

  public deleteUser(userId: string): Promise<any> {
    return this._http
      .delete(`${this._apiRoot}/account/${userId}`, this._requestOptions)
      .toPromise();
  }

  public disableUser(userId: string): Promise<any> {
    return this._http
      .patch(
        `${this._apiRoot}/account/${userId}`,
        [
          {
            op: 'replace',
            path: '/blocked',
            value: true,
          },
        ],
        this._requestOptions
      )
      .toPromise();
  }
  public updateUserDetail(data: any): Observable<any> {
    return this._http.put(`${this.apiURL}/updateUserDetails`, data, { headers: this._headers });
  }
  public getUserDetail(): Observable<any> {
    return this._http.get(`${this.apiURL}/getUserDetails`, { headers: this._headers });
  }
  public getTimezoneDetails(): Observable<any> {
    return this._http.get(`${this.apiURL}/getTimezoneDetails`);
  }

  public getUniqueSystemTraffic(request: any) {
    return this._http.post(`${this.apiURL}/uniqueSystemTrafficList`, request, { headers: this._headers });
  }

  public getUniqueDestinationSystemTraffic(request: any) {
    return this._http.post(`${this.apiURL}/uniqueSystemDestinationTrafficList`, request, { headers: this._headers });
  }

  public getDiscoverList(request: any) {
    return this._http.post(`${this.apiURL}/discoverList`, request, { headers: this._headers });
  }

  public addDiscoverQuery(request: any) {
    return this._http.post(`${this.apiURL}/addDiscoverQuery`, request, { headers: this._headers });
  }

  public getDiscoverQuery(employeeId: string) {
    return this._http.get(`${this.apiURL}/getDiscoverQuery/${employeeId}`, { headers: this._headers });
  }

  public deleteDiscoverQuery(queryId: string) {
    return this._http.delete(`${this.apiURL}/deleteDiscoverQuery/${queryId}`, { headers: this._headers });
  }

  public getEmailList(customerId: string) {
    return this._http.post(`${this.apiURL}/getEmailList`, { customerId }, { headers: this._headers });
  }

  public addEmailNotification(request: any) {
    return this._http.post(`${this.apiURL}/addEmailNotification`, request, { headers: this._headers });
  }

  public getEmailNotification(request: any) {
    return this._http.post(`${this.apiURL}/getEmailNotification`, request, { headers: this._headers });
  }

  public updateEmailNotification(notificationId: string, request: any) {
    return this._http.put(`${this.apiURL}/updateEmailNotification/${notificationId}`, request, { headers: this._headers });
  }

  public updateNotification(request: any) {
    return this._http.put(`${this.apiURL}/updateNotification`, request, { headers: this._headers });
  }

  public deleteEmailNotification(notificationId: string) {
    return this._http.delete(`${this.apiURL}/deleteEmailNotification/${notificationId}`, { headers: this._headers });
  }

  public updateUniqueIp(file: File): Observable<any> {
    const employeeId: string = localStorage.getItem('userId') as string;
    const payload = {
      employeeId,
      file
    };

    return this._http.post(`${this.apiURL}/updateUniqueIp`, payload);
  }

  public getAnomaliesList(dateRange: number = 1, trafficType: string = 'Internal', frequency: string = 'daily'): Observable<any> {
    const employeeId: string = localStorage.getItem('userId') as string;
    const url = `${this.apiURL}/getAnomaliesList/${employeeId}?dateRange=${dateRange}&trafficType=${trafficType}&frequency=${frequency}`;
    return this._http.get<any>(url);  }

  public updateAnomaly(hostIp: string): Observable<any> {
    const employeeId: string = localStorage.getItem('userId') as string;
    return this._http.post(`${this.apiURL}/updateAnomaly`, {employeeId, hostIp});
  }

  public getExternalTrafficSummary(dateRange: number = 1, includeAllRecords: boolean = false): Observable<any> {
    const employeeId: string = localStorage.getItem('userId') as string;
    const url = `${this.apiURL}/getExternalTrafficSummary/${employeeId}?dateRange=${dateRange}&includeAllRecords=${includeAllRecords}`;
    return this._http.get<any>(url);  
  }

  public getEastWestTraffic(page: number = 0, isAllData: boolean = false): Observable<any> {
    const employeeId: string = localStorage.getItem('userId') as string;
    const url = `${this.apiURL}/getEastWestTraffic/${employeeId}?page=${page}&isAllData=${isAllData}`;
    return this._http.get<any>(url);  
  }
}

export interface UEUser {
  id?: string;
  generatePassword?: boolean;
  profile?: {
    givenName?: string;
    familyName?: string;
    displayName?: string;
    picture?: string;
  };
  username: string;
  email: string;
  password?: string;
  phone?: {
    txt?: string;
  };
  metadata?: {};
}
