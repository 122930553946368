<div class="timeout-dialog">
    <mat-icon class="close-icon" matTooltip="Log out of your account" matTooltipClass="custom-tooltip"
        (click)="close(false)">close</mat-icon>
    <div class="panel">
        <div class="content">Please Enter MFA Verification Code to Continue</div>
        <form formGroup="loginForm">
            <!-- OTP Verification Section -->
            <ng-otp-input class="custom-otp" [formCtrl]="loginForm.get('verificationCode')"
                [config]="{length:6}" (keydown.enter)="verifyCode()"></ng-otp-input>
            <button type="button" class="btn-custom re-sync" mat-stroked-button (click)="verifyCode()">
                Verify Code
            </button>

        </form>
    </div>
</div>