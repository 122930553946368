import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import { getUserFeatures } from '../../state/app.selectors';
import { TileModel } from '../../models/tile.model';
import { MainMenu } from '../../models/account.model';
import { IconsName } from '../../models/enums/icons-name.enum';
import { LoadInsightTiles } from '../../views/landing-view/state/landing-view.actions';
import { Subscription } from 'rxjs';

@Injectable()
export class LandingService {

  public insightTiles: TileModel[];
  public toolTiles: TileModel[];
  public integrationTiles: TileModel[];
  private featureSubscription: Subscription;

  constructor(private store: Store<AppState>) {
    this.loadInsightTiles({});
    this.loadToolsTiles({});
    this.loadIntegrationTiles({});
    this.featureSubscription = this.store.pipe(
      select(getUserFeatures)
    ).subscribe(features => {
      if (features && features.mainMenu) {
        // this.loadInsightTiles(features.mainMenu);
        // this.loadToolsTiles(features.mainMenu);
        // this.loadIntegrationTiles(features.mainMenu);
      }
    });
    this.featureSubscription.unsubscribe();
  }

  private loadInsightTiles(_mainMenu: any): void {
    this.insightTiles = new Array<TileModel>();



    // //if (mainMenu.showSecurityEvents) {
    // this.insightTiles.push(
    //   {
    //     dashboard: 'security-events',
    //     icon: IconsName.BarChart,
    //     name: 'Security Events',
    //     link: '/security-events',
    //     image: 'security-events'
    //   }
    // );
    // // }
    // // if (mainMenu.showUserBehaviorMonitoring) {
    // this.insightTiles.push(
    //   {
    //     dashboard: 'user-behavior',
    //     icon: IconsName.UserMonitoring,
    //     name: 'User Behavior',
    //     link: '/user-behavior-dashboard',
    //     image: 'user-behavior-dashboard'
    //   }
    // );
    // // }
    // // if (mainMenu.showNetworkActivityDashboard) {
    // this.insightTiles.push(
    //   {
    //     dashboard: 'network-activity',
    //     icon: IconsName.NetworkActivity,
    //     name: 'Network Activity',
    //     link: '/network-activity',
    //     image: 'network-activity-dashboard'
    //   }
    // );
    // // }
    // // if (mainMenu.showPowershell) {
    // this.insightTiles.push(
    //   {
    //     dashboard: 'powershell',
    //     icon: IconsName.Powershell,
    //     name: 'Powershell',
    //     link: '/powershell',
    //     image: 'powershell'
    //   }
    // );
    // // }
    // // if (mainMenu.showAuditBeat) {
    // this.insightTiles.push(
    //   {
    //     dashboard: 'audit-beat',
    //     icon: IconsName.AuditBeat,
    //     name: 'Linux',
    //     link: '/linux',
    //     image: 'audit-beat'
    //   }
    // );
    // // }
    // // if (mainMenu.showFileBeat) {
    // this.insightTiles.push(
    //   {
    //     dashboard: 'file-beat',
    //     icon: IconsName.FileBeat,
    //     name: 'Firewall Activity',
    //     link: '/file-beat',
    //     image: 'file-beat'
    //   }
    // );
    // // }
    // // if (mainMenu.showPacketBeat) {
    // this.insightTiles.push(
    //   {
    //     dashboard: 'packet-beat',
    //     icon: IconsName.PacketBeat,
    //     name: 'What Are My Resources Connecting To?',
    //     link: '/packet-beat',
    //     image: 'packet-beat'
    //   }
    // );
    // // }
    // // if (mainMenu.showEnigmasDB) {
    // this.insightTiles.push(
    //   {
    //     dashboard: 'enigmas',
    //     icon: IconsName.EnigmasDB,
    //     isMatIcon: true,
    //     name: 'Enigmas Dashboard',
    //     link: '/enigmas-db',
    //     image: 'enigmas-db',
    //   }
    // );
    // // }


    
    this.insightTiles.push(
      {
        dashboard: 'enigmas',
        icon: IconsName.EnigmasDB,
        isMatIcon: true,
        name: 'Operations',
        link: '/operations',
        image: 'enigmas-db',
      }
    );
    this.insightTiles.push(
      {
        dashboard: 'enigmas',
        icon: IconsName.EnigmasDB,
        isMatIcon: true,
        name: 'Dynamic Hosts',
        link: '/ai-label',
        image: 'enigmas-db',
      }
    );
    this.insightTiles.push(
      {
        dashboard: 'enigmas',
        icon: IconsName.EnigmasDB,
        isMatIcon: true,
        name: 'Static Hosts',
        link: '/destination-jobs',
        image: 'enigmas-db',
      }
    );
    this.store.dispatch(LoadInsightTiles({ insightTiles: this.insightTiles }));
  }

  private loadToolsTiles(_mainMenu: any): void {
    this.toolTiles = new Array<TileModel>();
    // if (mainMenu.showNetworkActivity) {
    this.toolTiles.push(
      {
        dashboard: 'network',
        icon: IconsName.BarChart,
        name: 'Network Activity',
        link: '/attack-map',
        image: 'network-activity-tool'
      }
    );
    // }
    // if (mainMenu.showDiscover) {
    this.toolTiles.push(
      {
        dashboard: 'discover',
        icon: IconsName.Analysis,
        name: 'Discover',
        link: '/discover',
        image: 'discover-tool'
      }
    );
    // }
    // if (mainMenu.showAlerts) {
    this.toolTiles.push(
      {
        dashboard: 'alert',
        icon: IconsName.Alert,
        name: 'Alerts',
        link: '/alerts',
        image: 'alerts-tool'
      }
    );
    // }
    // if (mainMenu.showLogInsight) {
    this.toolTiles.push(
      {
        dashboard: 'log-insights',
        icon: IconsName.LogInsight,
        name: 'Log Insights',
        link: '/log-insights',
        image: 'log-insights-tool'
      }
    );
    // }
    // if (mainMenu.showIkeTool) {
    this.toolTiles.push(
      {
        dashboard: 'ike-tool',
        icon: IconsName.Ike,
        name: 'Ike Tool',
        link: '/ike-tool',
        image: 'ike-tool'
      }
    );
    //  }
  }

  private loadIntegrationTiles(_mainMenu: any): void {
    this.integrationTiles = new Array<TileModel>();
    // if (mainMenu.showUSecure) {
    this.integrationTiles.push(
      {
        icon: IconsName.OpenInNew,
        isMatIcon: true,
        name: 'EnigmaU',
        link: 'https://app.user-training.com/',
        image: 'u-secure',
        isExternal: true,
      }
    );
    // }
    // if (mainMenu.showEnigmaU) {
    this.integrationTiles.push(
      {
        icon: IconsName.EnigmaU,
        isMatIcon: true,
        name: 'Enigma U',
        link: '/enigma-u',
        image: 'enigma-u',
      }
    );
    //}
  }
}