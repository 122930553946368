// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// export const environment = {
//   production: false,
//   stsAuthority: 'https://qa.uecore.io/bmcu6dL8OTCI6DViSmKuv',
//   clientId: 'f46a36e7-e082-4d51-95d2-fe7865052c20', //'df979a79-5eba-4c4a-905f-6b8ddc1ef204',
//   clientRoot: 'http://localhost:8080/', //'https://enigma-glass-dsgajc66fa-uc.a.run.app/',
//   clientScope: 'openid profile email access',
//   apiRoot: 'https://qa.uecore.io/api/bmcu6dL8OTCI6DViSmKuv',
//   apiURL: 'https://enigma-glass-dsgajc66fa-uc.a.run.app/api'
// };

export const environment = {
  production: false,
  stsAuthority: process.env.STS_AUTHORITY,
  clientId:  process.env.CLIENT_ID,
  clientRoot:  process.env.CLIENT_ROOT,
  clientScope:  process.env.CLIENT_SCOPE,
  apiRoot:  process.env.API_ROOT,
  apiURL:  process.env.API_URL,
  productId: process.env.PRODUCT_ID,
  demoUser: process.env.DEMO_USER,
  demoUserPassword: process.env.DEMO_USER_PASSWORD
};

