import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuardLoadService, AuthGuardService, FeaturesGuard } from '@route';
import { SourceGuardService } from './route/sources.gaurd';
import { AccountGuard } from './guards/account.guard';
import { LoginGuard } from './route/login.guard';

export const routes: Routes = [
  {
    path: 'alerts',
    loadChildren: () =>
      import('src/app/views/alerts-dashboard/alerts-dashboard.module').then(
        (mod) => mod.AlertsDashboardModule
      ),
    canActivate: [FeaturesGuard],
    canLoad: [AuthGuardLoadService],
  },
  {
    path: 'security-events',
    loadChildren: () =>
      import(
        'src/app/views/security-events-dashboard/security-events-dashboard.module'
      ).then((mod) => mod.SecurityEventsDashboardModule),
    canActivate: [SourceGuardService],
    canLoad: [AuthGuardLoadService],
  },
  {
    path: 'user-behavior-dashboard',
    loadChildren: () =>
      import(
        'src/app/views/user-behavior-dashboard/user-behavior-dashboard.module'
      ).then((mod) => mod.UserBehaviorDashboardModule),
    canLoad: [AuthGuardLoadService],
  },
  {
    path: 'threat-map',
    loadChildren: () =>
      import('src/app/views/threat-map/threat-map.module').then(
        (mod) => mod.ThreatMapModule
      ),
    canActivate: [FeaturesGuard],
    canLoad: [AuthGuardLoadService],
  },
  {
    path: 'attack-map',
    loadChildren: () =>
      import('src/app/views/attack-map/attack-map.module').then(
        (mod) => mod.AttackMapModule
      ),
    canActivate: [FeaturesGuard],
    canLoad: [AuthGuardLoadService],
  },
  // {
  //   path: 'discover',
  //   loadChildren: () =>
  //     import('src/app/views/discover/discover.module').then(
  //       (mod) => mod.DiscoverModule
  //     ),
  //   canActivate: [SourceGuardService, FeaturesGuard],
  //   canLoad: [AuthGuardLoadService],
  // },
  // {
  //   path: ':dashboard/discover/:id',
  //   loadChildren: () =>
  //     import('src/app/views/discover/discover.module').then(
  //       (mod) => mod.DiscoverModule
  //     ),
  //   canActivate: [SourceGuardService],
  //   canLoad: [AuthGuardLoadService],
  // },
  {
    path: 'enigma-apps',
    loadChildren: () =>
      import('src/app/views/enigma-apps/enigma-apps.module').then(
        (mod) => mod.EnigmaAppsModule
      ),
    canActivate: [FeaturesGuard],
  },
  {
    path: 'register',
    loadChildren: () =>
      import('src/app/views/register/register.module').then(
        (mod) => mod.RegisterModule
      ),
    canLoad: [AuthGuardLoadService],
  },
  {
    path: 'server-error',
    loadChildren: () =>
      import('src/app/views/server-error/server-error.module').then(
        (mod) => mod.ServerErrorModule
      ),
    canActivate: [],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('src/app/views/settings/settings.module').then(
        (mod) => mod.SettingsModule
      ),
    canActivate: [LoginGuard],
    // canLoad: [AuthGuardLoadService],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('src/app/views/login/login.module').then((mod) => mod.LoginModule),
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('src/app/views/logout/logout.module').then((mod) => mod.LogoutModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('src/app/views/reports/reports.module').then(
        (mod) => mod.ReportsModule
      ),
    canActivate: [FeaturesGuard],
  },
  {
    path: 'under-development',
    loadChildren: () =>
      import('src/app/views/under-development/under-development.module').then(
        (mod) => mod.UnderDevelopmentModule
      ),
  },
  {
    path: 'log-insights',
    loadChildren: () =>
      import('src/app/views/log-insights/log-insights.module').then(
        (mod) => mod.LogInsightsModule
      ),
  },
  {
    path: 'ike-tool',
    loadChildren: () =>
      import('src/app/views/ike-tool/ike-tool.module').then(
        (mod) => mod.IkeToolModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('src/app/views/login/login.module').then((mod) => mod.LoginModule),
    //canActivate: [AuthGuardService, SourceGuardService],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('src/app/views/landing-view/landing-view.module').then(
        (mod) => mod.LandingViewModule
      ),
    canActivate: [LoginGuard],
    //canActivate: [AuthGuardService, SourceGuardService],
  },
  {
    path: 'network-activity',
    loadChildren: () =>
      import(
        'src/app/views/network-activity-dashboard/network-activity-dashboard.module'
      ).then((mod) => mod.NetworkActivityDashboardModule),
    canLoad: [AuthGuardLoadService],
  },
  {
    path: 'powershell',
    loadChildren: () =>
      import(
        'src/app/views/powershell-dashboard/powershell-dashboard.module'
      ).then((mod) => mod.PowershellDashboardModule),
    canLoad: [AuthGuardLoadService],
  },
  {
    path: 'linux',
    loadChildren: () =>
      import(
        'src/app/views/audit-beat-dashboard/audit-beat-dashboard.module'
      ).then((mod) => mod.AuditBeatDashboardModule),
    canLoad: [AuthGuardLoadService],
  },
  // {
  //   path: 'rules',
  //   loadChildren: () =>
  //     import('src/app/views/rules/rules.module').then((mod) => mod.RulesModule),
  //   canActivate: [FeaturesGuard],
  //   canLoad: [AuthGuardLoadService],
  // },
  {
    path: 'enigmas',
    loadChildren: () =>
      import('src/app/views/enigmas/enigmas.module').then(
        (mod) => mod.EnigmasModule
      ),
    canActivate: [FeaturesGuard],
    canLoad: [AuthGuardLoadService],
  },
  {
    path: 'operations',
    loadChildren: () =>
      import('src/app/views/enigma-ai-insights/enigma-ai-insights-module').then(
        (mod) => mod.EnigmaAiInsightsModule
      ),
    canActivate: [LoginGuard],
    //canActivate: [FeaturesGuard],
    //canLoad: [AuthGuardLoadService],
  },
  {
    path: 'insights',
    loadChildren: () =>
      import('src/app/views/enigma-reports/enigma-reports.module').then(
        (mod) => mod.EnigmaReportsModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'ai-label',
    loadChildren: () =>
      import('src/app/views/enigma-ai-label/enigma-ai-label-module').then(
        (mod) => mod.EnigmaAiLabelModule
      ),
    canActivate: [LoginGuard],
    //canActivate: [FeaturesGuard],
    // canLoad: [AuthGuardLoadService],
  },
  {
    path: 'destination-jobs',
    loadChildren: () =>
      import('src/app/views/enigma-destination-job/enigma-destination-job-module').then(
        (mod) => mod.EnigmaDestinationJobModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'top-anomalies',
    loadChildren: () =>
      import('src/app/views/enigma-anomalies-dashboard/enigma-anomalies-dashboard.module').then(
        (mod) => mod.EnigmaAnomaliesDashboardModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'discover',
    loadChildren: () =>
      import('src/app/views/discover/discover.module').then(
        (mod) => mod.DiscoverModule
      ),
    canActivate: [LoginGuard]
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('src/app/views/notification/notification.module').then((mod) => mod.NotificationModule),
    canActivate: [LoginGuard],
  },
  // {
  //   path: ':dashboard/discover/:id',
  //   loadChildren: () =>
  //     import('src/app/views/discover/discover.module').then(
  //       (mod) => mod.DiscoverModule
  //     ),
  //   canActivate: [LoginGuard]
  // },
  {
    path: 'file-beat',
    loadChildren: () =>
      import(
        'src/app/views/file-beat-dashboard/file-beat-dashboard.module'
      ).then((mod) => mod.FileBeatDashboardModule),
    canLoad: [AuthGuardLoadService],
  },
  {
    path: 'packet-beat',
    loadChildren: () =>
      import(
        'src/app/views/packet-beat-dashboard/packet-beat-dashboard.module'
      ).then((mod) => mod.PacketBeatDashboardModule),
    canLoad: [AuthGuardLoadService],
  },
  {
    path: 'enigmas-db',
    loadChildren: () =>
      import('src/app/views/enigmas-dashboard/enigmas-dashboard.module').then(
        (mod) => mod.EnigmasDashboardModule
      ),
    canActivate: [FeaturesGuard],
    canLoad: [AuthGuardLoadService],
  },
  {
    path: 'enigma-u',
    loadChildren: () =>
      import('src/app/views/enigma-u/enigma-u.module').then(
        (mod) => mod.EnigmaUModule
      ),
    canActivate: [FeaturesGuard, AccountGuard],
    canLoad: [AuthGuardLoadService],
  },
  {
    path: 'signin-callback',
    loadChildren: () =>
      import('src/app/views/signin-callback/signin-callback.module').then(
        (mod) => mod.SigninCallbackModule
      ),
  },
  {
    path: 'signout-callback',
    loadChildren: () =>
      import('src/app/views/signout-callback/signout-callback.module').then(
        (mod) => mod.SignoutCallbackModule
      ),
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

export const AppRoutes: ModuleWithProviders<RouterModule> =
  RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' });
