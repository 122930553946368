export enum IconsName {
    Alert = 'alert',
    Analysis = 'analysis',
    Analytics = 'analytics',
    App = 'app',
    BarChart = 'bar-chart',
    ClipBoard = 'clipboard',
    Earth = 'earth',
    Engineering = 'engineering',
    LineChart = 'line-chart',
    MegaPhone = 'megaphone',
    Networking = 'networking',
    PieChart = 'pie-chart',
    Settings = 'settings',
    Solution = 'solution',
    LogInsight = 'log-file',
    UserMonitoring = 'user-monitoring',
    Ike = 'ike',
    NetworkActivity = 'network-activity',
    Powershell = 'powershell',
    AuditBeat = 'audit-beat',
    WatcherAlerts = 'binoculars',
    FileBeat = 'file-beat',
    PacketBeat = 'packet-beat',
    Rules = 'wrench',
    Enigmas = 'maze',
    EnigmasDB = 'verified_user',
    OpenInNew = 'open_in_new',
    EnigmaU = 'lock_clock',
    AIInsights = 'ai-insights',
    LabelJob = 'label-job',
    DestinationJob = 'destination-job',
    AnomalyDetection = 'anomaly-detection',
    Insights = 'insights',
    Operations = 'operations',
}
